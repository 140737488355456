<template>
  <div id="signin_page">
    <div class="login-bg">
      <div class="container h-100">
        <div class="row justify-content-center h-100">
          <div class="col-xl-6 logo-wrapper d-flex">
            <img src="/assets/login_logo.png" alt="Company Logo" class="m-auto"/>
          </div>
          <div class="col-xl-6 d-flex">
            <div class="form-input-content login-form m-auto w-60">
              <form class="mt-5 mb-5">
                <div class="form-group input-group">
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    class="form-control login-form-control"
                    placeholder="User ID"
                  />
                  <div class="input-group-text custom-icon user-icon"></div>
                </div>
                <transition name="slide-fade" mode='out-in'>
                <div v-if="isSignIn" key="0">
                  <div class="form-group input-group">
                    <input
                      id="password"
                      v-model="password"
                      type="password"
                      class="form-control login-form-control"
                      placeholder="Password"
                    />
                    <div class="input-group-text custom-icon password-icon"></div>
                  </div>
                  <div class="form-row justify-content-center pt-3">
                    <div class="form-group w-70">
                      <button @click.prevent="onSignInSubmit" type="submit" class="btn btn-primary w-100 sign-in-btn">
                        Sign in
                      </button>
                    </div>
                  </div>
                  <div @click="isSignIn = false" class="text-center mt-2 forgot-password-wrapper">
                      Forgot Password
                  </div>
                </div>
                <div v-else key="1">
                  <div class="form-row justify-content-center pt-3">
                  <div class="form-group w-70">
                    <button type="submit" @click.prevent="onForgotPWSubmit" class="btn btn-primary reset-password-btn w-100">
                      Reset Password
                    </button>
                    </div>
                  </div>
                  <div @click="isSignIn = true" class="text-center mt-2 login-link-wrapper">
                      Login to your account
                  </div>
                </div>
                </transition>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as authActions from "../../store/modules/auth/actions";
import * as uiActions from "../../store/modules/_shared/ui/actions"

export default {
  name: "SignIn",
  data() {
    return {
      email: "",
      password: "",
      env: process.env.VUE_APP_NODE_ENV,
      isSignIn: true,
    };
  },
  methods: {
    onSignInSubmit() {
      const { email, password } = this;
      this.$store
        .dispatch(authActions.AUTH_REQUEST, { loginId: email, password: password })
        .then(resp => {
          console.log(resp)
          if (resp.status == 'ok') {
            this.$router.push("/", { reload: true });
            location.reload(); 
          }
        }).catch(err => {
          console.log(err)
          this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
            message: err.errorMessage
          })
        });
    },
    onForgotPWSubmit() {
      this.$store
        .dispatch(authActions.PASSWORD_RESET_REQUEST, { loginId: this.email })
        .then(resp => {
          if (resp ? resp.status == 'ok' : false) {
            this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
              message: "Password reset email sent to '" + this.email + "'.",
              timeOut: true
            });
            this.email = "";
          } else {
            this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
              message: "Email not found",
              timeOut: true
            });
          }
        });
    },
  }
};
</script>
<style lang="scss">
#signin_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('/assets/bg_deco.png');
  background-repeat: no-repeat;
  .w-60{
    width: 60%;
  }
  .w-70{
    width: 70%;
  }
  .logo-wrapper{
    border-right: 1px solid #D8D8DA;
  }
  .login-link-wrapper, .forgot-password-wrapper{
    color: #1C000A;
    opacity: 0.55;
    cursor: pointer;
  }
  .sign-in-btn, .sign-in-btn:hover{
    box-shadow: 0 13px 10px -5px #ff0a1665!important;
  }
  .form-control.login-form-control{
    border-right: none!important;
  }
}
</style>